import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Container } from "../global"
import Step from "./components/step"

const Steps = ({ intl }) => {
  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: { relativeDirectory: { eq: "steps" }, extension: { eq: "png" } }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 522) {
                originalName
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StepsWrapper>
      <Container>
        <Step
          imgUrl={images.AllPostImages.edges[0].node.childImageSharp.fluid}
          title={intl.formatMessage({ id: "step_01" })}
        />
        <Step
          imgUrl={images.AllPostImages.edges[2].node.childImageSharp.fluid}
          title={intl.formatMessage({ id: "step_02" })}
        />
        <Step
          imgUrl={images.AllPostImages.edges[2].node.childImageSharp.fluid}
          title={intl.formatMessage({ id: "step_03" })}
        />
        <Step
          imgUrl={images.AllPostImages.edges[5].node.childImageSharp.fluid}
          title={intl.formatMessage({ id: "step_04" })}
        />
        <Step
          imgUrl={images.AllPostImages.edges[4].node.childImageSharp.fluid}
          title={intl.formatMessage({ id: "step_05" })}
        />
      </Container>
    </StepsWrapper>
  )
}

export default injectIntl(Steps)

const StepsWrapper = styled.div`
  margin-top: 80px;

  .comingSoon_text {
    text-align: center;
    color: ${({ theme }) => theme.color.grey.grey02};
    ${({ theme }) => theme.font_size.small};
    font-family: ${({ theme }) => theme.font.extraBold};
    letter-spacing: 4px;
    text-transform: uppercase;
    margin: 0 0 34px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`
