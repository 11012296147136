import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Container } from "../global"
import Video1DashboardWebmURL from "../../videos/mobile/03_ES_Map.webm"
import Video1DashboardMP4URL from "../../videos/mobile/03_ES_Map.mp4"
import Video2DashboardWebmURL from "../../videos/mobile/04_ES_Actividad_Fisica.webm"
import Video2DashboardMP4URL from "../../videos/mobile/04_ES_Actividad_Fisica.mp4"
import Title from "../common/title"
import VideoElement from "./components/videoElement"

const Description = ({ intl }) => {
  const posters = useStaticQuery(graphql`
    query {
      dashboard: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "1_ES_Dashboard" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      overview: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "2_ES_Overview_Health" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <DescriptionWrapper id="description">
      <Container>
        <VideoElement
          poster={posters.dashboard.childImageSharp.fluid.src}
          videosURL={{
            webm: Video1DashboardWebmURL,
            mp4: Video1DashboardMP4URL,
          }}
        />
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({ id: "h3-description-home" })}
        />
        <p>{intl.formatMessage({ id: "h3-description-text" })}</p>
        <VideoElement
          poster={posters.overview.childImageSharp.fluid.src}
          marginTop="120px"
          videosURL={{
            webm: Video2DashboardWebmURL,
            mp4: Video2DashboardMP4URL,
          }}
        />
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({ id: "h3-description-home-2" })}
        />
        <p>{intl.formatMessage({ id: "h3-description-text-2" })}</p>
      </Container>
    </DescriptionWrapper>
  )
}

export default injectIntl(Description)

const DescriptionWrapper = styled.div`
  p {
    ${({ theme }) => theme.font_size.medium};
    text-align: left;
    margin: 16px 0 0;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`
