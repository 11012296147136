import React, { useState } from "react"
import { injectIntl } from "gatsby-plugin-intl"
import Layout from "../components/common/layout/layout"
import SEO from "../components/common/layout/seo"
import Header from "../components/home/header"
import SubHeader from "../components/home/subHeader"
import Description from "../components/home/description"
import Steps from "../components/home/steps"
import Connect from "../components/home/connect"
import Photos from "../components/home/photos"
import Senior from "../components/home/senior"
import Body from "../components/home/body"
import Watches from "../components/home/watches"
import WatchesMobile from "../components/home/watchesMobile"

const IndexPage = ({ location, intl }) => {
  const SEOTitle = intl.formatMessage({ id: "SEO_title_home" })
  const SEODescription = intl.formatMessage({ id: "SEO_description_home" })
  const [showScrollBar, setShowScrollBar] = useState(false)

  return (
    <Layout {...{ location, showScrollBar }}>
      <SEO title={SEOTitle} description={SEODescription} />
      <Header {...{ setShowScrollBar }} />
      <Watches {...{ location }} />
      <WatchesMobile {...{ location }} />
      <SubHeader />
      <Description />
      <Steps />
      <Connect />
      <Photos />
      <Senior />
      <Body />
    </Layout>
  )
}

export default injectIntl(IndexPage)
