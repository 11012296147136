import React from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { gsap } from "gsap"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Title from "../../../common/title"
import { SectionsWrapper } from "../../body"

const Description = ({ intl }) => {
  const images = useStaticQuery(graphql`
    query {
      image1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_sub_header_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 440) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      image2: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_sub_header_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 440) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <Controller>
      <Scene triggerHook="onLeave" duration={500} pin offset={-100}>
        {(progress, event) => {
          if (event.type === "enter" && event.scrollDirection === "FORWARD") {
            gsap.to(".scale-image", { duration: 5, scale: 1 })
          }

          if (event.type === "enter" && event.scrollDirection === "REVERSE") {
            gsap.to(".scale-image", { duration: 5, scale: 1.3 })
          }
          return (
            <div>
              <SectionsWrapper style={{ zIndex: 0, position: "relative" }}>
                <Timeline totalProgress={progress} paused>
                  <Section>
                    <div className="text-area">
                      <Title
                        hType="h3"
                        color="purple"
                        noMargin
                        fontSizeDesktop="xxlarge"
                        text={intl.formatMessage({
                          id: "h3-subheader-home",
                        })}
                      />
                      <p>{intl.formatMessage({ id: "text-subheader-home" })}</p>
                    </div>

                    {/* <Timeline totalProgress={progress} paused>
                      <Vector13Wrapper>
                        <svg
                          width="427"
                          height="322"
                          viewBox="0 0 427 322"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Tween
                            from={{
                              svgDraw: 0,
                            }}
                            to={{
                              svgDraw: 1,
                            }}
                          >
                            <path
                              d="M88.0998 149.486C88.0998 149.486 83.2999 67.0858 40.5998 67.0858C-2.10015 67.0858 13.2999 176.686 30.0998 212.386C48.0998 250.686 80.3998 289.386 112.4 290.186C144.4 290.986 225.7 259.586 241 101.586C247.8 50.5858 242.4 26.5858 234.7 15.5858C226.8 4.38584 209.3 8.18584 206.3 21.5858C202.8 37.2858 206.7 66.8858 251.9 114.186C299.2 163.686 349.3 160.286 378.8 151.886"
                              stroke="#FFCF54"
                              strokeWidth="6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </Tween>
                        </svg>
                      </Vector13Wrapper>
                    </Timeline> */}
                    <Tween from={{ x: "0%" }} to={{ x: "28.5%" }}>
                      <div className="image-area">
                        <OverFlowImage>
                          <StyledImage
                            className="scale-image"
                            fluid={images.image2.childImageSharp.fluid}
                          />
                        </OverFlowImage>
                      </div>
                    </Tween>
                  </Section>
                </Timeline>
                <Timeline totalProgress={progress} paused>
                  <Section>
                    <div className="text-area">
                      <Title
                        hType="h3"
                        color="purple"
                        noMargin
                        fontSizeDesktop="xxlarge"
                        text={intl.formatMessage({
                          id: "h3-subheader-home-2",
                        })}
                      />
                      <p>
                        {intl.formatMessage({
                          id: "text-subheader-home-2",
                        })}
                      </p>
                    </div>
                    {/* <Timeline totalProgress={progress} paused>
                      <Vector14Wrapper>
                        <svg
                          width="269"
                          height="209"
                          viewBox="0 0 269 209"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <Tween
                            from={{
                              svgDraw: 0,
                            }}
                            to={{
                              svgDraw: 1,
                            }}
                          >
                            <path
                              d="M265.8 201.79C197.4 198.99 151 117.79 159.3 54.79C167.7 -8.30999 221.9 -7.91 221.9 49.99C221.9 107.89 179.5 124.19 142.3 134.69C105.1 145.19 48.2 144.49 5 107.69"
                              fill="none"
                              stroke="#FF7F81"
                              strokeWidth="6"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </Tween>
                        </svg>
                      </Vector14Wrapper>
                    </Timeline> */}
                    <Tween from={{ x: "0%" }} to={{ x: "-28.5%" }}>
                      <div className="image-area">
                        <OverFlowImage>
                          <StyledImage
                            className="scale-image"
                            fluid={images.image1.childImageSharp.fluid}
                          />
                        </OverFlowImage>
                      </div>
                    </Tween>
                  </Section>
                </Timeline>
              </SectionsWrapper>
            </div>
          )
        }}
      </Scene>
    </Controller>
  )
}

/* const Vector13Wrapper = styled.div`
  position: absolute;
  z-index: 2;
  top: 315px;
  left: 15px;

  @media (min-width: ${props => props.theme.screen.lg}) {
    top: 355px;
    left: 186px;
  }
` */

/* const Vector14Wrapper = styled.div`
  position: absolute;
  top: 230px;
  left: -215px;
  z-index: 2;

  @media (min-width: ${props => props.theme.screen.lg}) {
    top: 270px;
    left: -250px;
  }
` */

const Section = styled.div`
  position: relative;

  .text-area {
    max-width: 220px;
    margin: 0 auto;

    p {
      ${({ theme }) => theme.font_size.medium};
      margin: 16px 0 0;

      @media (min-width: ${props => props.theme.screen.lg}) {
        ${({ theme }) => theme.font_size.large};
      }
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      max-width: 325px;
    }
  }

  .image-area {
    margin-top: 70px;

    .scale-image {
      transform: scale(1.3);
    }
  }

  &:last-child {
    margin-top: 100px;
  }
`
const OverFlowImage = styled.div`
  overflow: hidden;
  width: min-content;
`

const StyledImage = styled(Img)`
  width: 305px;
  overflow: hidden;

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 440px;
  }
`

export default injectIntl(Description)
