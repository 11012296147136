import React from "react"
/* import anime from "animejs/lib/anime.es.js" */
import styled from "styled-components"
/* import VisibilitySensor from "react-visibility-sensor" */
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import { Container } from "../global"
/* import Vector13 from "../../images/svgs/vector13SubHeader1.svg"
import Vector14 from "../../images/svgs/vector14SubHeader2.svg" */
import Title from "../common/title"

const SubHeader = ({ intl }) => {
  /*   const [isVisibleVector13, setIsVisibleVector13] = useState(false)
  const [isVisibleVector14, setIsVisibleVector14] = useState(false)
  const [animationVector13, setAnimationVector13] = useState(null)
  const [animationVector14, setAnimationVector14] = useState(null) */

  const images = useStaticQuery(graphql`
    query {
      image1: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_sub_header_1" }
      ) {
        childImageSharp {
          fluid(maxWidth: 685) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      image2: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_sub_header_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 685) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  /*   useEffect(() => {
    setAnimationVector13(
      anime.timeline({ easing: "linear", autoplay: false }).add({
        targets: ".vector13",
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 2000,
        direction: "forwards",
        delay: 500,
        easing: "easeOutQuint",
      })
      .add({
          targets: ".subheader-image1",
          scale: [1.2, 1.0],
          duration: 5000,
          elasticity: 400,
        })
    )
    setAnimationVector14(
      anime.timeline({ easing: "linear", autoplay: false }).add({
        targets: ".vector14",
        strokeDashoffset: [anime.setDashoffset, 1200],
        duration: 1000,
        direction: "forwards",
        delay: 500,
        easing: "easeOutQuint",
      })
      .add({
          targets: ".subheader-image2 img",
          scale: [1.2, 1.0],
          duration: 5000,
          elasticity: 400,
        })
    )
  }, []) */

  /*   useEffect(() => {
    if (isVisibleVector13) {
      animationVector13.play()
    }
  }, [isVisibleVector13, animationVector13])

  useEffect(() => {
    if (isVisibleVector14) {
      animationVector14.play()
    }
  }, [isVisibleVector14, animationVector14]) */

  /*   const animateVector13 = () => {
    setIsVisibleVector13(true)
  }

  const animateVector14 = () => {
    setIsVisibleVector14(true)
  } */

  return (
    <SubHeaderWrapper id="subHeader">
      <Container>
        <SubHeaderSection>
          <Title
            hType="h3"
            color="purple"
            center
            text={intl.formatMessage({ id: "h3-subheader-home" })}
          />
          <p>{intl.formatMessage({ id: "text-subheader-home" })}</p>
          <ImageWrapper>
            {/* <VisibilitySensor
              partialVisibility
              onChange={e => e && animateVector13()}
            >
              <SvgContainer width="425px">
                <Vector13 />
              </SvgContainer>
            </VisibilitySensor> */}
            <OverFlowImage>
              <StyledImage
                className="st0-imageTop subheader-image1"
                fluid={images.image2.childImageSharp.fluid}
              />
            </OverFlowImage>
          </ImageWrapper>
        </SubHeaderSection>
        <SubHeaderSection>
          <Title
            hType="h2"
            color="purple"
            center
            text={intl.formatMessage({ id: "h3-subheader-home-2" })}
          />
          <p>{intl.formatMessage({ id: "text-subheader-home-2" })}</p>
          <ImageWrapper position="right">
            {/* <VisibilitySensor
              partialVisibility
              onChange={e => e && animateVector14()}
              offset={{ top: 350 }}
            >
              <SvgContainer left="-70px">
                <Vector14 />
              </SvgContainer> 
            </VisibilitySensor> */}
            <OverFlowImage>
              <StyledImage
                className="st0-imageTop subheader-image2"
                fluid={images.image1.childImageSharp.fluid}
              />
            </OverFlowImage>
          </ImageWrapper>
        </SubHeaderSection>
      </Container>
    </SubHeaderWrapper>
  )
}

export default injectIntl(SubHeader)

const SubHeaderWrapper = styled.div`
  margin-top: 140px;

  p {
    ${({ theme }) => theme.font_size.medium};
    text-align: center;
    margin: 16px 0 0;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const SubHeaderSection = styled.div`
  margin-bottom: 123px;
`

const ImageWrapper = styled.div`
  margin-top: 120px;
  margin-left: -24px;
  margin-right: -24px;
  position: relative;
`

const OverFlowImage = styled.div`
  /* overflow: hidden;
  width: min-content; */
`

/* const SvgContainer = styled.div`
  top: -52px;
  left: ${({ left }) => (left ? left : "-20px")};
  position: absolute;
  z-index: 1;

  svg {
    width: ${({ width }) => (width ? width : "auto")};
    height: auto;
  }
` */

const StyledImage = styled(Img)`
  width: 100%;
  opacity: 0;

  /* img {
    transform: scale(1.2);
  } */
`
