import React, { Fragment, useRef } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { gsap } from "gsap"
import { Quint } from "gsap/gsap-core"
import { injectIntl } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import VideoAnimation from "../../videos/desktop/Durcal_ES_Pulsera.webm"
import VideoAnimationMP4 from "../../videos/desktop/Durcal_ES_Pulsera.mp4"
import { Container } from "../global"
import Title from "../common/title"
import Button from "../common/button"
import { checkLanguage, paths } from "../../utils"

const Watches = ({ intl, location }) => {
  const videoRef = useRef(null)
  const image1Ref = useRef(null)
  let videoTimer = null
  let image1Start, image1End

  const images = useStaticQuery(graphql`
    query {
      iphoneCase: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_iphone_case_desktop" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            aspectRatio
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pulsera: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "durcal_pulsera_home" }
      ) {
        childImageSharp {
          fluid(maxWidth: 365, quality: 100) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const handleVideo = (ref, start, end) => {
    clearTimeout(videoTimer)
    ref.current.currentTime = start
    ref.current.play()
    videoTimer = setTimeout(() => ref.current && ref.current.pause(), end)
  }

  return (
    <WatchesWrapper>
      <Container>
        <Controller>
          <Scene triggerHook="onCenter" offset={300} duration={3900} pin>
            {progress => (
              <SectionsWrapper>
                <VideoWrapper>
                  <Timeline
                    target={
                      <Fragment>
                        <EmptyBox style={{ zIndex: "2" }}>
                          <div className="photos-wrapper">
                            <PhotoWrapper ref={image1Ref}>
                              <StyledPhoto
                                fluid={images.pulsera.childImageSharp.fluid}
                              />
                            </PhotoWrapper>
                          </div>
                          <VideoWrapperWithCase>
                            <ImagePhoneWrapper>
                              <StyledPhone
                                className={
                                  images.iphoneCase.childImageSharp.fluid
                                    .aspectRatio
                                }
                                imgStyle={{ objectFit: "contain" }}
                                fluid={images.iphoneCase.childImageSharp.fluid}
                              />
                            </ImagePhoneWrapper>

                            <video
                              ref={videoRef}
                              muted
                              width="100%"
                              height="100%"
                              preload="auto"
                            >
                              <source src={VideoAnimation} type="video/webm" />
                              <source
                                src={VideoAnimationMP4}
                                type="video/mp4"
                              />
                            </video>
                          </VideoWrapperWithCase>
                        </EmptyBox>
                      </Fragment>
                    }
                    totalProgress={progress}
                    paused
                  >
                    <Scene triggerHook="onCenter" duration={1200} pin>
                      {prg => (
                        <SectionsAnimatedWrapper>
                          <div className="title-area-fixed">
                            <Title
                              hType="h3"
                              color="purple"
                              noMargin
                              fontSizeDesktop="xxlarge"
                              text={intl.formatMessage({
                                id: "watch_section_peacefulness_title",
                              })}
                            />
                          </div>
                          <Timeline
                            totalProgress={prg}
                            target={
                              <Fragment>
                                <div className="text-area-animation">
                                  <p>
                                    {intl.formatMessage({
                                      id: "watch_section_peacefulness_text",
                                    })}
                                  </p>
                                </div>
                                <div className="text-area-animation">
                                  <p>
                                    {intl.formatMessage({
                                      id: "watch_section_peacefulness_text_2",
                                    })}
                                  </p>
                                  <Button
                                    to={`/${checkLanguage(location, intl)}${
                                      paths[intl.locale].pulsera
                                    }`}
                                    btnType="primary"
                                    rounded
                                  >
                                    {intl.formatMessage({
                                      id: "watch_section_peacefulness_button",
                                    })}
                                  </Button>
                                </div>
                              </Fragment>
                            }
                          >
                            <Tween
                              from={{ css: { opacity: 0 } }}
                              to={{ css: { display: "block", opacity: 1 } }}
                              duration={50}
                              target={0}
                              onStart={() => {
                                handleVideo(videoRef, 0, 3000)
                              }}
                            />
                            <Tween duration={300} target={0} />
                            <Tween
                              from={{ css: { opacity: 1 } }}
                              to={{
                                css: {
                                  display: "none",
                                  opacity: 0,
                                  y: "-10%",
                                },
                              }}
                              duration={50}
                              target={0}
                              onReverseComplete={() => {
                                handleVideo(videoRef, 0, 3000)
                              }}
                            />
                            <Tween
                              from={{ css: { opacity: 0 } }}
                              to={{
                                css: {
                                  display: "block",
                                  opacity: 1,
                                  top: "-50%",
                                },
                              }}
                              duration={50}
                              target={1}
                              onReverseComplete={() => {
                                image1Start.pause()
                                image1End = gsap.to(image1Ref.current, {
                                  duration: 0.1,
                                  x: "0",
                                  y: "0",
                                  opacity: 0,
                                })
                              }}
                              onStart={() => {
                                handleVideo(videoRef, 3, 6000)

                                if (image1End) {
                                  image1End.pause()
                                }
                                image1Start = gsap.to(image1Ref.current, {
                                  duration: 0.5,
                                  x: "-250",
                                  y: "40",
                                  opacity: 1,
                                  ease: Quint.easeOut,
                                  delay: 0.5,
                                })
                              }}
                            />
                            <Tween duration={700} target={1} />
                            <Tween
                              from={{ css: { opacity: 1 } }}
                              to={{
                                css: {
                                  display: "none",
                                  opacity: 0,
                                  y: "-10%",
                                },
                              }}
                              duration={50}
                              target={1}
                              onReverseComplete={() => {
                                handleVideo(videoRef, 3, 6000)
                              }}
                            />
                          </Timeline>
                        </SectionsAnimatedWrapper>
                      )}
                    </Scene>
                  </Timeline>
                </VideoWrapper>
              </SectionsWrapper>
            )}
          </Scene>
        </Controller>
      </Container>
    </WatchesWrapper>
  )
}

const StyledPhone = styled(Img)`
  top: -20px;
  left: -9px;
`

const PhotoWrapper = styled.div`
  position: absolute;
  right: -200px;
  top: 300px;
  opacity: 0;
  z-index: 999;
`

const StyledPhoto = styled(Img)`
  width: 220px;
`

const EmptyBox = styled.div`
  width: 449px;
  height: 639px;
  display: block;
  right: 100px;
  position: absolute;

  video {
    position: relative;
    border-radius: 35%;
    height: 82%;
    top: 9%;
  }
`

const ImagePhoneWrapper = styled.div`
  position: absolute;
  z-index: 555;
  width: 104%;
`

const VideoWrapperWithCase = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const SectionsAnimatedWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 45px;
  width: 100%;
  height: 70vh;

  .title-area-fixed {
    max-width: 300px;
    margin-top: 15%;

    @media (min-width: ${props => props.theme.screen.lg}) {
      max-width: 420px;
    }
  }

  .text-area-animation {
    display: none;
    max-width: 300px;

    p {
      ${({ theme }) => theme.font_size.medium};
      margin: 32px 0;

      @media (min-width: ${props => props.theme.screen.lg}) {
        ${({ theme }) => theme.font_size.large};
      }
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      max-width: 420px;
    }
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding-left: 115px;
  }
`

const VideoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;

  .photos-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .text-area-animation-senior {
    margin-top: 20%;
    display: none;
    z-index: 2;
    width: 100%;
    position: absolute;
    height: 75vh;
    display: flex;
    justify-content: space-between;
    padding: 0 75px;

    > div {
      max-width: 275px;

      @media (min-width: ${props => props.theme.screen.lg}) {
        max-width: 327px;
      }
    }

    > p {
      max-width: 221px;
      color: ${({ theme }) => theme.color.white};
      ${({ theme }) => theme.font_size.medium};
      display: flex;
      align-self: center;

      @media (min-width: ${props => props.theme.screen.lg}) {
        max-width: 325px;
        ${({ theme }) => theme.font_size.large};
      }
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      padding: 0;
    }
  }
`

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 70vh;
  padding: 0 80px;

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding: 0;
  }
`

const WatchesWrapper = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    padding-top: 180px;
    position: relative;
    z-index: 11;
  }
`

export default injectIntl(Watches)
