import React from "react"
import styled from "styled-components"
import { injectIntl } from "react-intl"
import { graphql, useStaticQuery } from "gatsby"
import Video1WebmURL from "../../videos/mobile/01_ES_Tranquilidad_Durcal.webm"
import Video1MP4URL from "../../videos/mobile/01_ES_Tranquilidad_Durcal.mp4"
import Video2WebmURL from "../../videos/mobile/02_ES_Tranquilidad_Durcal_Pulsera.webm"
import Video2MP4URL from "../../videos/mobile/02_ES_Tranquilidad_Durcal_Pulsera.mp4"
import Title from "../common/title"
import VideoElement from "./components/videoElement"
import { Container } from "../global"
import Button from "../common/button"
import { checkLanguage, paths } from "../../utils"

const WatchesMobile = ({ intl, location }) => {
  const posters = useStaticQuery(graphql`
    query {
      dashboard: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "01_ES_Tranquilidad_Durcal" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      overview: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "02_ES_Tranquilidad_Durcal_Pulsera" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <DescriptionWrapper id="description">
      <Container>
        <VideoElement
          poster={posters.dashboard.childImageSharp.fluid.src}
          videosURL={{
            webm: Video1WebmURL,
            mp4: Video1MP4URL,
          }}
        />
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({ id: "watch_section_peacefulness_title" })}
        />
        <p>{intl.formatMessage({ id: "watch_section_peacefulness_text" })}</p>
        <VideoElement
          poster={posters.overview.childImageSharp.fluid.src}
          marginTop="120px"
          videosURL={{
            webm: Video2WebmURL,
            mp4: Video2MP4URL,
          }}
        />
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({
            id: "watch_section_peacefulness_title_2",
          })}
        />
        <p>{intl.formatMessage({ id: "watch_section_peacefulness_text_2" })}</p>
        <Button
          to={`/${checkLanguage(location, intl)}${paths[intl.locale].pulsera}`}
          btnType="primary"
          rounded
          fullwidth
        >
          {intl.formatMessage({
            id: "watch_section_peacefulness_button",
          })}
        </Button>
      </Container>
    </DescriptionWrapper>
  )
}

const DescriptionWrapper = styled.div`
  p {
    ${({ theme }) => theme.font_size.medium};
    text-align: left;
    margin: 16px 0;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

export default injectIntl(WatchesMobile)
