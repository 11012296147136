import React, { useRef, useState } from "react"
import styled from "styled-components"
import anime from "animejs/lib/anime.es.js"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"
import { Container } from "../global"
import Video5DashboardWebmURL from "../../videos/mobile/07_ES_Fotos.webm"
import Video5DashboardMP4URL from "../../videos/mobile/07_ES_Fotos.mp4"
import Title from "../common/title"
import VideoElement from "./components/videoElement"

const Photos = ({ intl }) => {
  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)

  const [isImagesVisible, setIsImagesVisible] = useState(false)

  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "photos" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 145) {
                originalName
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
      pictures: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "5_ES_Modal_Pictures" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  const handleImages = () => {
    setIsImagesVisible(true)

    anime
      .timeline({ easing: "linear" })
      .add({
        targets: image1Ref.current,
        translateX: -105,
        translateY: -220,
        duration: 500,
        easing: "easeOutQuint",
      })
      .add({
        targets: image2Ref.current,
        translateX: 165,
        duration: 500,
        easing: "easeOutQuint",
      })
      .add({
        targets: image3Ref.current,
        translateX: -145,
        translateY: 190,
        duration: 500,
        easing: "easeOutQuint",
      })
  }

  return (
    <PhotosWrapper id="photos">
      <Container>
        <div className="images-wrapper">
          <ImageWrapper ref={image1Ref}>
            <StyledImage
              fluid={images.AllPostImages.edges[0].node.childImageSharp.fluid}
            />
          </ImageWrapper>
          <ImageWrapper ref={image2Ref}>
            <StyledImage
              fluid={images.AllPostImages.edges[2].node.childImageSharp.fluid}
            />
          </ImageWrapper>
          <ImageWrapper ref={image3Ref}>
            <StyledImage
              fluid={images.AllPostImages.edges[1].node.childImageSharp.fluid}
            />
          </ImageWrapper>
        </div>
        <VisibilitySensor
          active={!isImagesVisible}
          onChange={e => e && handleImages()}
        >
          <VideoElement
            poster={images.pictures.childImageSharp.fluid.src}
            marginTop="120px"
            videosURL={{
              webm: Video5DashboardWebmURL,
              mp4: Video5DashboardMP4URL,
            }}
          />
        </VisibilitySensor>
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({ id: "h3-photos-home" })}
        />
        <p>{intl.formatMessage({ id: "h3-photos-text" })}</p>
      </Container>
    </PhotosWrapper>
  )
}

export default injectIntl(Photos)

const PhotosWrapper = styled.div`
  .images-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  p {
    ${({ theme }) => theme.font_size.medium};
    text-align: left;
    margin: 16px 0 0;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  top: 355px;
`

const StyledImage = styled(Img)`
  width: 145px;
`
