import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import anime from "animejs/lib/anime.es.js"
import { injectIntl } from "gatsby-plugin-intl"
import VisibilitySensor from "react-visibility-sensor"
import { Container } from "../global"
import Video6DashboardWebmURL from "../../videos/mobile/08_Vista_Adaptada.webm"
import Video6DashboardMP4URL from "../../videos/mobile/08_Vista_Adaptada.mp4"
import Title from "../common/title"
import VideoElement from "./components/videoElement"

const Senior = ({ intl }) => {
  const image1Ref = useRef(null)

  const [isBlockVisible, setIsBlockVisible] = useState(false)
  const [blockAnimation, setBlockAnimation] = useState(null)
  const [blockReverseAnimation, setBlockReverseAnimation] = useState(null)

  const poster = useStaticQuery(graphql`
    query {
      senior: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "6_ES_Senior" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  useEffect(() => {
    setBlockAnimation(
      anime.timeline({ easing: "linear", autoplay: false }).add({
        targets: image1Ref.current,
        scale: 10.0,
        duration: 750,
        autoplay: false,
      })
    )

    setBlockReverseAnimation(
      anime.timeline({ easing: "linear", autoplay: false }).add({
        targets: image1Ref.current,
        scale: [10.0, 1.0],
        duration: 750,
        autoplay: false,
      })
    )
  }, [])

  useEffect(() => {
    if (blockAnimation && blockReverseAnimation) {
      if (isBlockVisible) {
        blockAnimation.play()
      } else {
        blockReverseAnimation.play()
      }
    }
  }, [isBlockVisible, blockAnimation, blockReverseAnimation])

  return (
    <VisibilitySensor
      partialVisibility
      minTopValue={900}
      onChange={e => setIsBlockVisible(e)}
    >
      <SeniorWrapper id="senior">
        <Container>
          <div className="block-purple-wrapper">
            <BlockPurple ref={image1Ref} id="block-purple" />
          </div>
          <VideoElement
            poster={poster.senior.childImageSharp.fluid.src}
            marginTop="120px"
            videosURL={{
              webm: Video6DashboardWebmURL,
              mp4: Video6DashboardMP4URL,
            }}
            color="white"
            caption={intl.formatMessage({ id: "caption-senior-text" })}
          />
          <Title
            hType="h3"
            color="white"
            noMargin
            fontSize="xxxlarge"
            text={intl.formatMessage({ id: "h3-senior-home" })}
          />
          <p className="second_text">
            {intl.formatMessage({ id: "h3-senior-text" })}
          </p>
        </Container>
      </SeniorWrapper>
    </VisibilitySensor>
  )
}

export default injectIntl(Senior)

const SeniorWrapper = styled.div`
  position: relative;

  .block-purple-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .second_text {
    ${({ theme }) => theme.font_size.medium};
    position: relative;
    margin: 16px 0 0;
    color: ${({ theme }) => theme.color.white};
    padding-bottom: 120px;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const BlockPurple = styled.div`
  position: absolute;
  top: 355px;
  background-color: ${({ theme }) => theme.color.purple};
  height: 200px;
  width: 200px;
  border-radius: 50%;
`
