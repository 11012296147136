import React, { useRef, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"

const VideoElement = ({
  videosURL,
  intl,
  marginTop,
  caption,
  color,
  poster = "",
}) => {
  const videoRef = useRef(null)
  const [isVideoVisible, setIsVideoVisible] = useState(false)

  const image = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_iphone_case" }
      ) {
        childImageSharp {
          fluid(maxWidth: 425) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  const handleVideoVisibility = () => {
    setIsVideoVisible(true)
    videoRef.current.play()
  }

  return (
    <VideoDashboardWrapper {...{ marginTop, color }}>
      <VideoContainer>
        <ImageWrapper>
          <StyledImage fluid={image.file.childImageSharp.fluid} />
        </ImageWrapper>
        <VisibilitySensor
          active={!isVideoVisible}
          onChange={e => e && handleVideoVisibility()}
          partialVisibility
        >
          <video
            ref={videoRef}
            muted
            width="100%"
            height="100%"
            playsInline
            preload="auto"
            poster={poster}
          >
            <source src={videosURL.webm} type="video/webm" />
            <source src={videosURL.mp4} type="video/mp4" />
          </video>
        </VisibilitySensor>
      </VideoContainer>
      {/* <p className="caption">
        {caption ? caption : intl.formatMessage({ id: "for-you-caption" })}
      </p> */}
    </VideoDashboardWrapper>
  )
}

export default injectIntl(VideoElement)

const VideoDashboardWrapper = styled.div`
  margin-bottom: 16px;
  ${({ marginTop }) => marginTop && `margin-top: ${marginTop};`};

  video {
    width: 220px;
    margin-top: 70px;
  }

  .caption {
    position: relative;
    ${({ theme }) => theme.font_size.small};
    color: ${({ theme, color }) =>
      color ? theme.color[color] : theme.color.grey.grey02};
    letter-spacing: 4px;
    text-align: left;
    text-transform: uppercase;
  }
`

const VideoContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 55px;
  width: 100%;
`

const ImageWrapper = styled.div`
  position: absolute;
`

const StyledImage = styled(Img)`
  width: 425px;
`
