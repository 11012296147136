import React from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { Container } from "../global"
import Video3DashboardWebmURL from "../../videos/mobile/05_ES_Seguridad.webm"
import Video3DashboardMP4URL from "../../videos/mobile/05_ES_Seguridad.mp4"
import Title from "../common/title"
import VideoElement from "./components/videoElement"

const Connect = ({ intl }) => {
  const posters = useStaticQuery(graphql`
    query {
      security: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "3_ES_Overview_Security" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      modal: file(
        relativeDirectory: { eq: "posters" }
        name: { eq: "4_ES_Modal_Call" }
      ) {
        childImageSharp {
          fluid(maxWidth: 220) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  return (
    <ConnectWrapper id="connect">
      <Container>
        <VideoElement
          poster={posters.security.childImageSharp.fluid.src}
          videosURL={{
            webm: Video3DashboardWebmURL,
            mp4: Video3DashboardMP4URL,
          }}
        />
        <Title
          hType="h3"
          color="purple"
          noMargin
          text={intl.formatMessage({ id: "h3-connect-home" })}
        />
        <p>{intl.formatMessage({ id: "h3-connect-text" })}</p>
      </Container>
    </ConnectWrapper>
  )
}

export default injectIntl(Connect)

const ConnectWrapper = styled.div`
  p {
    ${({ theme }) => theme.font_size.medium};
    text-align: left;
    margin: 16px 0 0;
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`
