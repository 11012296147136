import React, { useEffect, useRef } from "react"
import anime from "animejs/lib/anime.es.js"
import styled, { keyframes } from "styled-components"
import { isIOS } from "react-device-detect"
import VisibilitySensor from "react-visibility-sensor"
import { graphql, useStaticQuery } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import Img from "gatsby-image"
import lottie from "lottie-web"
import Logo from "../../images/svgs/logo.svg"
/* import Vector16 from "../../images/svgs/vector16HomeTop.svg" */
import animation from "../../animations/ic_arrow_scroll.json"
import { APP_STORES_LINK } from "../../utils"
import { Container } from "../global"
import Button from "../common/button"
import Title from "../common/title"

const Header = ({ intl, setShowScrollBar }) => {
  const arrowScrollLottieRef = useRef(null)

  const images = useStaticQuery(graphql`
    query {
      file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_header_top" }
      ) {
        childImageSharp {
          fluid(maxWidth: 685) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      topMobile: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_durcal_header_top_mobile" }
      ) {
        childImageSharp {
          fluid(maxWidth: 375) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      appleStore: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "ic_apple_store" }
      ) {
        childImageSharp {
          fluid(maxWidth: 20) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      googlePlay: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "ic_google_play" }
      ) {
        childImageSharp {
          fluid(maxWidth: 20) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
      backgroundBadged: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "background-badge-header-home" }
      ) {
        childImageSharp {
          fluid(maxWidth: 160) {
            originalName
            src
            srcSet
            aspectRatio
            sizes
          }
        }
      }
    }
  `)

  useEffect(() => {
    const lottieAnimation = lottie.loadAnimation({
      container: arrowScrollLottieRef.current,
      loop: false,
      autoplay: true,
      animationData: animation,
    })

    lottieAnimation.addEventListener("complete", function() {
      setTimeout(function() {
        lottieAnimation.goToAndPlay(0)
      }, 10000)
    })

    anime.timeline({ loop: false }).add({
      targets: ".section-animated",
      translateY: -5,
      duration: 1550,
      delay: 800,
      opacity: 1,
    })
    anime.timeline({ easing: "linear" }).add({
      targets: ".st0-imageTop",
      opacity: 1,
      duration: 500,
      easing: "easeInExpo",
    })
    anime.timeline({ easing: "linear" }).add({
      targets: ".st0",
      strokeDashoffset: [anime.setDashoffset, 0],
      duration: 1000,
      delay: 1000,
      easing: "easeOutQuint",
    })
  }, [])

  return (
    <VisibilitySensor partialVisibility onChange={e => setShowScrollBar(!e)}>
      <HeaderWrapper id="top">
        <Container>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>

          <HeaderSections>
            <BadgedWrapper>
              <StyledBadgedImage
                fluid={images.backgroundBadged.childImageSharp.fluid}
              />
              <div className="badged-copy-wrapper">
                <p className="badged-copy">Abuelos incluidos</p>
              </div>
            </BadgedWrapper>
            <div>
              <Title
                hType="h1"
                noMargin
                fontSize="xxlarge"
                fontSizeDesktop="xxxlarge"
                text={intl.formatMessage({ id: "h1-title-home" })}
              />
              <h2 className="section-animated">
                {intl.formatMessage({ id: "h2-title-home" })}
              </h2>
              <ButtonWrapper className="section-animated">
                <Button
                  to={isIOS ? APP_STORES_LINK.apple : APP_STORES_LINK.google}
                  btnType="primary"
                >
                  {isIOS ? (
                    <>
                      <StyledLogoImage
                        fluid={images.appleStore.childImageSharp.fluid}
                      />
                      {intl.formatMessage({ id: "app-store" })}
                    </>
                  ) : (
                    <>
                      <StyledLogoImage
                        fluid={images.googlePlay.childImageSharp.fluid}
                      />
                      {intl.formatMessage({ id: "google-play" })}
                    </>
                  )}
                </Button>
              </ButtonWrapper>
              <ButtonWrapperDesktop className="section-animated">
                <Button to={APP_STORES_LINK.apple} btnType="primary">
                  <>
                    <StyledLogoImage
                      fluid={images.appleStore.childImageSharp.fluid}
                    />
                    {intl.formatMessage({ id: "app-store" })}
                  </>
                </Button>
                <Button to={APP_STORES_LINK.google} btnType="primary">
                  <>
                    <StyledLogoImage
                      fluid={images.googlePlay.childImageSharp.fluid}
                    />
                    {intl.formatMessage({ id: "google-play" })}
                  </>
                </Button>
              </ButtonWrapperDesktop>
            </div>
            <ImageWrapper>
              {/* <div className="svgContainer">
                <Vector16 />
              </div> */}
              <StyledImage
                className="st0-imageTop"
                fluid={images.file.childImageSharp.fluid}
              />
              <StyledImageMobile
                className="st0-imageTop"
                fluid={images.topMobile.childImageSharp.fluid}
              />
            </ImageWrapper>
          </HeaderSections>
          <LottieWrapper ref={arrowScrollLottieRef} />
        </Container>
      </HeaderWrapper>
    </VisibilitySensor>
  )
}

export default injectIntl(Header)

const LottieWrapper = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    width: 40px;
    margin: 0 auto;
  }
`

const HeaderWrapper = styled.header`
  margin-top: 55px;

  @media (min-width: ${props => props.theme.screen.md}) {
    padding-bottom: 100px;
  }
`

const LogoWrapper = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const HeaderSections = styled.div`
  margin-top: 85px;
  position: relative;

  .section-animated {
    opacity: 0;
  }

  h2 {
    margin: 29px 0 0;
    ${({ theme }) => theme.font_size.medium};
    font-family: ${({ theme }) => theme.font.medium};

    @media (min-width: ${props => props.theme.screen.lg}) {
      ${({ theme }) => theme.font_size.large};
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`

const ButtonWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 80px;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
    margin-bottom: 0;
  }
`

const ButtonWrapperDesktop = styled(ButtonWrapper)`
  display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: flex;
    > div:first-child {
      margin-right: 8px;
    }
  }
`

const ImageWrapper = styled.div`
  margin-left: -24px;
  margin-right: -24px;
  position: relative;

  .svgContainer {
    top: 0;
    position: absolute;

    svg {
      width: 502px;

      @media (min-width: ${props => props.theme.screen.md}) {
        width: 573px;
      }

      @media (min-width: ${props => props.theme.screen.lg}) {
        width: 763px;
      }
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    margin-left: 60px;
  }
`

const StyledImage = styled(Img)`
  display: none;
  opacity: 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    width: 520px;
    margin-left: 50px;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 685px;
    margin-left: 50px;
  }
`

const StyledImageMobile = styled(Img)`
  width: 100%;
  opacity: 0;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`

const StyledLogoImage = styled(Img)`
  width: 20px;
  margin-right: 8px;
`

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const StyledBadgedImage = styled(Img)`
  width: 160px;
  animation: ${rotate} 15s linear infinite;
`

const BadgedWrapper = styled.div`
  width: 160px;
  height: 160px;
  position: absolute;
  top: -100px;
  right: -25px;

  .badged-copy-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;

    .badged-copy {
      margin: 0;
      ${({ theme }) => theme.font_size.small};
      font-family: ${({ theme }) => theme.font.extraBold};
      color: ${({ theme }) => theme.color.purpleSemi};
      text-transform: uppercase;
      padding: 0 30px;
      text-align: center;
      letter-spacing: 4px;
      transform: rotate(9deg);

      @media (min-width: ${props => props.theme.screen.md}) {
        transform: rotate(-15deg);
      }
    }
  }

  @media (min-width: ${props => props.theme.screen.md}) {
    top: -55px;
    left: -45px;
    right: initial;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    top: -10px;
    left: -55px;
    right: initial;
  }
`
