import React, { useRef, useState } from "react"
import anime from "animejs/lib/anime.es.js"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import Img from "gatsby-image"

const Step = ({ imgUrl, title }) => {
  const stepRef = useRef(null)
  const [isStepVisible, setIsStepVisible] = useState(false)

  const handleStepVisibility = () => {
    setIsStepVisible(true)
    anime.timeline({ loop: false }).add({
      targets: stepRef.current,
      opacity: 1,
      duration: 300,
      easing: "easeInExpo",
    })
  }

  return (
    <StepWrapper>
      <VisibilitySensor
        active={!isStepVisible}
        onChange={e => e && handleStepVisibility()}
      >
        <StepContainer ref={stepRef}>
          <StyledImage fluid={imgUrl} />
          <p>{title}</p>
        </StepContainer>
      </VisibilitySensor>
    </StepWrapper>
  )
}

export default Step

const StepWrapper = styled.div`
  margin-bottom: 50px;

  p {
    color: ${({ theme }) => theme.color.purple};
    ${({ theme }) => theme.font_size.large};
    font-family: ${({ theme }) => theme.font.extraBold};
    text-align: center;
  }
`

const StyledImage = styled(Img)`
  width: 80px;
`

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
`
