import React, { Fragment, useRef } from "react"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
import { gsap } from "gsap"
import styled from "styled-components"
import { injectIntl } from "gatsby-plugin-intl"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { Container } from "../global"
import VideoAnimation from "../../videos/desktop/Durcal_ES_Cuidador.webm"
import VideoAnimationMP4 from "../../videos/desktop/Durcal_ES_Cuidador.mp4"
import VideoAnimation2 from "../../videos/desktop/Durcal_ES_Senior.webm"
import VideoAnimation2MP4 from "../../videos/desktop/Durcal_ES_Senior.mp4"
import theme from "../../styles/theme"
import Title from "../common/title"
import Description from "./components/desktop/description"
import { Quint } from "gsap/gsap-core"

const Body = ({ intl }) => {
  const videoRef = useRef(null)
  const video2Ref = useRef(null)
  const image1Ref = useRef(null)
  const image2Ref = useRef(null)
  const image3Ref = useRef(null)
  const blockPurpleRef = useRef(null)
  const listRef = useRef(null)
  let videoTimer = null
  let image1Start, image2Start, image3Start, image1End, image2End, image3End

  const images = useStaticQuery(graphql`
    query {
      AllPostImages: allFile(
        filter: {
          relativeDirectory: { eq: "photos" }
          extension: { eq: "png" }
        }
        sort: { fields: [name], order: ASC }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid(maxWidth: 220) {
                originalName
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
      iphoneCase: file(
        sourceInstanceName: { eq: "product" }
        name: { eq: "img_iphone_case_desktop" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1000) {
            aspectRatio
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const handleVideo = (ref, start, end) => {
    clearTimeout(videoTimer)
    ref.current.currentTime = start
    ref.current.play()
    videoTimer = setTimeout(() => ref.current && ref.current.pause(), end)
  }

  return (
    <BodyWrapper>
      <BlockPurpleWrapper>
        <BlockPurple ref={blockPurpleRef} />
      </BlockPurpleWrapper>
      <Container>
        <Description />
        <Controller>
          <Scene triggerHook="onCenter" duration={3900} pin>
            {progress => (
              <div>
                <SectionsWrapper>
                  <VideoWrapper>
                    <Timeline
                      target={
                        <Fragment>
                          <EmptyBox style={{ zIndex: "2" }}>
                            <div className="photos-wrapper">
                              <PhotoWrapper ref={image1Ref}>
                                <StyledPhoto
                                  fluid={
                                    images.AllPostImages.edges[0].node
                                      .childImageSharp.fluid
                                  }
                                />
                              </PhotoWrapper>
                              <PhotoWrapper ref={image2Ref}>
                                <StyledPhoto
                                  fluid={
                                    images.AllPostImages.edges[2].node
                                      .childImageSharp.fluid
                                  }
                                />
                              </PhotoWrapper>
                              <PhotoWrapper ref={image3Ref}>
                                <StyledPhoto
                                  fluid={
                                    images.AllPostImages.edges[1].node
                                      .childImageSharp.fluid
                                  }
                                />
                              </PhotoWrapper>
                            </div>
                            <VideoWrapperWithCase>
                              <ImagePhoneWrapper>
                                <StyledPhone
                                  className={
                                    images.iphoneCase.childImageSharp.fluid
                                      .aspectRatio
                                  }
                                  imgStyle={{ objectFit: "contain" }}
                                  fluid={
                                    images.iphoneCase.childImageSharp.fluid
                                  }
                                />
                              </ImagePhoneWrapper>

                              <video
                                ref={videoRef}
                                muted
                                width="100%"
                                height="100%"
                                preload="auto"
                              >
                                <source
                                  src={VideoAnimation}
                                  type="video/webm"
                                />
                                <source
                                  src={VideoAnimationMP4}
                                  type="video/mp4"
                                />
                              </video>
                            </VideoWrapperWithCase>
                          </EmptyBox>
                          <EmptyBox
                            style={{
                              display: "none",
                              width: "449px",
                              height: "639px",
                              marginTop: "10%",
                              transform: "scale(0.9)",
                            }}
                          >
                            <VideoWrapperWithCase>
                              <ImagePhoneWrapper>
                                <StyledPhone
                                  className={
                                    images.iphoneCase.childImageSharp.fluid
                                      .aspectRatio
                                  }
                                  imgStyle={{ objectFit: "contain" }}
                                  fluid={
                                    images.iphoneCase.childImageSharp.fluid
                                  }
                                />
                              </ImagePhoneWrapper>
                              <video
                                ref={video2Ref}
                                muted
                                width="100%"
                                height="100%"
                                preload="auto"
                              >
                                <source
                                  src={VideoAnimation2}
                                  type="video/webm"
                                />
                                <source
                                  src={VideoAnimation2MP4}
                                  type="video/mp4"
                                />
                              </video>
                            </VideoWrapperWithCase>
                          </EmptyBox>
                        </Fragment>
                      }
                      totalProgress={progress}
                      paused
                    >
                      <Tween
                        to={{ top: "-500px" }}
                        target={0}
                        ease="elastic.in(1, 0.3)"
                        duration={400}
                        onStart={() => {
                          handleVideo(videoRef, 0, 3000)
                        }}
                      />

                      <Tween
                        to={{ width: "449px", height: "639px", y: "25%" }}
                        target={0}
                        duration={200}
                        onReverseComplete={() => {
                          handleVideo(videoRef, 0, 3000)
                        }}
                      />

                      <Tween to={{ x: "55%" }} target={0} duration={200} />
                      <Scene triggerHook="onCenter" duration={4300} pin>
                        {prg => (
                          <SectionsAnimatedWrapper>
                            <Timeline
                              totalProgress={prg}
                              target={
                                <Fragment>
                                  <div className="text-area-animation">
                                    <Title
                                      hType="h3"
                                      color="purple"
                                      noMargin
                                      fontSizeDesktop="xxlarge"
                                      text={intl.formatMessage({
                                        id: "h3-description-home",
                                      })}
                                    />
                                    <p>
                                      {intl.formatMessage({
                                        id: "h3-description-text",
                                      })}
                                    </p>
                                  </div>
                                  <div className="text-area-animation">
                                    <Title
                                      hType="h3"
                                      color="purple"
                                      noMargin
                                      fontSizeDesktop="xxlarge"
                                      text={intl.formatMessage({
                                        id: "h3-description-home-2",
                                      })}
                                    />
                                    <p>
                                      {intl.formatMessage({
                                        id: "h3-description-text-2",
                                      })}
                                    </p>
                                  </div>
                                </Fragment>
                              }
                            >
                              <Tween
                                from={{ css: { opacity: 0 } }}
                                to={{ css: { display: "block", opacity: 1 } }}
                                duration={50}
                                target={0}
                                onStart={() => {
                                  handleVideo(videoRef, 4, 5000)
                                }}
                              />
                              <Tween duration={500} target={0} />
                              <Tween
                                from={{ css: { opacity: 1 } }}
                                to={{
                                  css: {
                                    display: "none",
                                    opacity: 0,
                                    y: "-10%",
                                  },
                                }}
                                duration={50}
                                target={0}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 4, 5000)
                                }}
                              />
                              <Tween
                                from={{ css: { opacity: 0 } }}
                                to={{
                                  css: {
                                    display: "block",
                                    opacity: 1,
                                    top: "-50%",
                                  },
                                }}
                                duration={50}
                                target={1}
                                onStart={() => {
                                  handleVideo(videoRef, 10, 5000)
                                }}
                              />
                              <Tween duration={500} target={1} />
                              <Tween
                                from={{ css: { opacity: 1 } }}
                                to={{
                                  css: {
                                    display: "none",
                                    opacity: 0,
                                    y: "-10%",
                                  },
                                }}
                                duration={50}
                                target={1}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 10, 5000)
                                }}
                                onComplete={() => {
                                  gsap.to(".step-list", {
                                    duration: 1,
                                    opacity: 1,
                                    y: "3%",
                                    display: "block",
                                  })
                                }}
                              />
                            </Timeline>

                            <Timeline
                              wrapper={
                                <ul ref={listRef} className="step-list" />
                              }
                              target={
                                <Fragment>
                                  <li>
                                    {intl.formatMessage({
                                      id: "step_01",
                                    })}
                                  </li>
                                  <li>
                                    {intl.formatMessage({
                                      id: "step_02",
                                    })}
                                  </li>
                                  <li>
                                    {intl.formatMessage({
                                      id: "step_03",
                                    })}
                                  </li>
                                  <li>
                                    {intl.formatMessage({
                                      id: "step_04",
                                    })}
                                  </li>
                                  <li>
                                    {intl.formatMessage({
                                      id: "step_05",
                                    })}
                                  </li>
                                </Fragment>
                              }
                            >
                              <Tween
                                onReverseComplete={() => {
                                  gsap.to(listRef.current, {
                                    duration: 1,
                                    opacity: 0,
                                    y: "-1%",
                                    display: "none",
                                  })
                                }}
                                to={{
                                  color: theme.color.purple,
                                }}
                                onStart={() => {
                                  handleVideo(videoRef, 15, 2000)
                                }}
                                duration={1}
                                target={0}
                              />
                              <Tween target={0} duration={500} />
                              <Tween
                                to={{ color: theme.color.grey.grey02 }}
                                target={0}
                                duration={1}
                              />
                              <Tween
                                to={{
                                  color: theme.color.purple,
                                }}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 15, 2000)
                                }}
                                onStart={() => {
                                  handleVideo(videoRef, 17, 2000)
                                }}
                                duration={1}
                                target={1}
                              />
                              <Tween target={1} duration={400} />
                              <Tween
                                to={{ color: theme.color.grey.grey02 }}
                                target={1}
                                duration={1}
                              />
                              <Tween
                                to={{
                                  color: theme.color.purple,
                                }}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 17, 2000)
                                }}
                                onStart={() => {
                                  handleVideo(videoRef, 20, 2000)
                                }}
                                duration={1}
                                target={2}
                              />
                              <Tween target={2} duration={400} />
                              <Tween
                                to={{ color: theme.color.grey.grey02 }}
                                target={2}
                                duration={1}
                              />
                              <Tween
                                to={{
                                  color: theme.color.purple,
                                }}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 20, 2000)
                                }}
                                onStart={() => {
                                  handleVideo(videoRef, 22, 2000)
                                }}
                                duration={1}
                                target={3}
                              />
                              <Tween target={3} duration={400} />
                              <Tween
                                to={{ color: theme.color.grey.grey02 }}
                                target={3}
                                duration={1}
                              />
                              <Tween
                                to={{
                                  color: theme.color.purple,
                                }}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 22, 2000)
                                }}
                                onStart={() => {
                                  handleVideo(videoRef, 24, 2000)
                                }}
                                duration={1}
                                target={4}
                              />
                              <Tween target={4} duration={400} />
                              <Tween
                                to={{ color: theme.color.grey.grey02 }}
                                target={4}
                                duration={1}
                                onComplete={() => {
                                  gsap.to(".step-list", {
                                    duration: 1,
                                    opacity: 0,
                                    y: "-1%",
                                    display: "none",
                                  })
                                }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <Fragment>
                                  <div className="text-area-animation">
                                    <Title
                                      hType="h3"
                                      color="purple"
                                      noMargin
                                      fontSizeDesktop="xxlarge"
                                      text={intl.formatMessage({
                                        id: "h3-connect-home",
                                      })}
                                    />
                                    <p>
                                      {intl.formatMessage({
                                        id: "h3-connect-text",
                                      })}
                                    </p>
                                  </div>
                                </Fragment>
                              }
                            >
                              <Tween
                                onReverseComplete={() => {
                                  gsap.to(".step-list", {
                                    duration: 1,
                                    opacity: 1,
                                    y: "1%",
                                    display: "block",
                                  })
                                  handleVideo(videoRef, 24, 2000)
                                }}
                                from={{ css: { opacity: 0 } }}
                                to={{ css: { display: "block", opacity: 1 } }}
                                duration={150}
                                target={0}
                                onStart={() => {
                                  handleVideo(videoRef, 30, 5000)
                                }}
                              />
                              <Tween duration={400} target={0} />
                              <Tween
                                ffrom={{ css: { opacity: 1 } }}
                                to={{
                                  css: {
                                    display: "none",
                                    opacity: 0,
                                    y: "-10%",
                                  },
                                }}
                                duration={150}
                                target={0}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 30, 5000)
                                }}
                              />
                            </Timeline>
                            <Timeline
                              target={
                                <Fragment>
                                  <div className="text-area-animation">
                                    <Title
                                      hType="h3"
                                      color="purple"
                                      noMargin
                                      fontSizeDesktop="xxlarge"
                                      text={intl.formatMessage({
                                        id: "h3-photos-home",
                                      })}
                                    />
                                    <p>
                                      {intl.formatMessage({
                                        id: "h3-photos-text",
                                      })}
                                    </p>
                                  </div>
                                </Fragment>
                              }
                            >
                              <Tween
                                from={{ css: { opacity: 0 } }}
                                to={{ css: { display: "block", opacity: 1 } }}
                                duration={150}
                                target={0}
                                onReverseComplete={() => {
                                  image1Start.pause()
                                  image2Start.pause()
                                  image3Start.pause()
                                  image1End = gsap.to(image1Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                  image2End = gsap.to(image2Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                  image3End = gsap.to(image3Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                }}
                                onStart={() => {
                                  if (image1End) {
                                    image1End.pause()
                                    image2End.pause()
                                    image3End.pause()
                                  }
                                  handleVideo(videoRef, 37, 7000)
                                  image1Start = gsap.to(image1Ref.current, {
                                    duration: 0.5,
                                    x: "-130",
                                    y: "-300",
                                    ease: Quint.easeOut,
                                    delay: 0.5,
                                  })
                                  image2Start = gsap.to(image2Ref.current, {
                                    duration: 0.5,
                                    x: "225",
                                    y: "10",
                                    ease: Quint.easeOut,
                                    delay: 1,
                                  })
                                  image3Start = gsap.to(image3Ref.current, {
                                    duration: 0.5,
                                    x: "-185",
                                    y: "240",
                                    ease: Quint.easeOut,
                                    delay: 1.5,
                                  })
                                }}
                              />
                              <Tween duration={400} target={0} />
                              <Tween
                                from={{ css: { opacity: 1 } }}
                                to={{
                                  css: {
                                    display: "none",
                                    opacity: 0,
                                    y: "-10%",
                                  },
                                }}
                                duration={150}
                                target={0}
                                onReverseComplete={() => {
                                  handleVideo(videoRef, 37, 7000)
                                  if (image1End) {
                                    image1End.pause()
                                    image2End.pause()
                                    image3End.pause()
                                  }
                                  image1Start = gsap.to(image1Ref.current, {
                                    duration: 0.5,
                                    x: "-130",
                                    y: "-300",
                                    ease: Quint.easeOut,
                                    delay: 0.5,
                                  })
                                  image2Start = gsap.to(image2Ref.current, {
                                    duration: 0.5,
                                    x: "225",
                                    y: "10",
                                    ease: Quint.easeOut,
                                    delay: 1,
                                  })
                                  image3Start = gsap.to(image3Ref.current, {
                                    duration: 0.5,
                                    x: "-185",
                                    y: "240",
                                    ease: Quint.easeOut,
                                    delay: 1.5,
                                  })
                                }}
                                onComplete={() => {
                                  image1Start.pause()
                                  image2Start.pause()
                                  image3Start.pause()
                                  image1End = gsap.to(image1Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                  image2End = gsap.to(image2Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                  image3End = gsap.to(image3Ref.current, {
                                    duration: 0.1,
                                    x: "0",
                                    y: "0",
                                  })
                                }}
                              />
                            </Timeline>
                          </SectionsAnimatedWrapper>
                        )}
                      </Scene>
                      <Tween to={{ x: "0%" }} target={0} duration={400} />
                      <Tween to={{ css: { display: "block" } }} target={1} />
                      <Tween to={{ x: "-25%" }} target={0} duration={25} />
                      <Tween to={{ x: "31%" }} target={1} duration={25} />
                      <Tween to={{ scale: 1 }} target={1} duration={5} />
                      <Tween to={{ scale: 0.95 }} target={0} duration={5} />
                      <Tween to={{ css: { zIndex: 2 } }} target={1} />
                      <Tween
                        to={{ x: "0%" }}
                        target={1}
                        duration={25}
                        position="=1"
                      />
                      <Tween
                        to={{ x: "0%" }}
                        target={0}
                        duration={25}
                        position="=1"
                      />
                      <Tween
                        target={1}
                        duration={200}
                        onReverseComplete={() => {
                          gsap.to(blockPurpleRef.current, {
                            duration: 1,
                            scale: 1,
                            display: "none",
                          })
                          document
                            .getElementById("LogoDurcal")
                            .classList.remove("white")
                        }}
                        onStart={() => {
                          handleVideo(video2Ref, 0, 5000)
                          gsap.to(blockPurpleRef.current, {
                            duration: 1.5,
                            scale: 15,
                            display: "block",
                          })

                          document
                            .getElementById("LogoDurcal")
                            .classList.add("white")
                        }}
                      />
                      <Tween
                        duration={400}
                        from={{ css: { display: "none" } }}
                        to={{ css: { display: "flex" } }}
                      >
                        <div className="text-area-animation-senior">
                          <div>
                            <Title
                              hType="h3"
                              noMargin
                              color="white"
                              fontSize="xxlarge"
                              fontSizeDesktop="xxxlarge"
                              text={intl.formatMessage({
                                id: "h3-senior-home",
                              })}
                            />
                          </div>
                          <p>
                            {intl.formatMessage({
                              id: "h3-senior-text",
                            })}
                          </p>
                        </div>
                      </Tween>
                    </Timeline>
                  </VideoWrapper>
                </SectionsWrapper>
              </div>
            )}
          </Scene>
        </Controller>
      </Container>
    </BodyWrapper>
  )
}

const BlockPurpleWrapper = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  width: 100%;
  top: 150px;
  z-index: 0;
`

const BlockPurple = styled.div`
  display: none;
  position: absolute;
  top: 215px;
  background-color: ${({ theme }) => theme.color.purpleLight};
  height: 200px;
  width: 200px;
  border-radius: 50%;
`

const SectionsAnimatedWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 45px;
  width: 100%;
  height: 100vh;

  .text-area-animation {
    display: none;
    max-width: 300px;
    margin-top: 45%;

    p {
      ${({ theme }) => theme.font_size.medium};
      margin: 32px 0 0;

      @media (min-width: ${props => props.theme.screen.lg}) {
        ${({ theme }) => theme.font_size.large};
      }
    }

  /*  .caption {
      margin-bottom: 16px;
      position: relative;
      ${({ theme }) => theme.font_size.small};
      color: ${({ theme }) => theme.color.grey.grey02};
      letter-spacing: 4px;
      text-align: left;
      text-transform: uppercase;
    } */

    @media (min-width: ${props => props.theme.screen.lg}) {
      max-width: 420px;
      margin-top: 30%;
    }
  }

  .step-list {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    opacity: 0;
    margin-top: 35%;

    li {
      ${({ theme }) => theme.font_size.large};
      color: ${({ theme }) => theme.color.grey.grey02};
      font-family: ${({ theme }) => theme.font.extraBold};
      margin-bottom: 8px;

      @media (min-width: ${props => props.theme.screen.lg}) {
        ${({ theme }) => theme.font_size.xlarge};
      }
    }

    .coming-soon {
      margin-top: 32px;
      margin-bottom: 16px;
      position: relative;
      ${({ theme }) => theme.font_size.small};
      letter-spacing: 4px;
      text-transform: uppercase;
    }

    @media (min-width: ${props => props.theme.screen.lg}) {
      margin-top: 25%;
    }
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding-left: 115px;
  }
`

const VideoWrapper = styled.div`
  margin-top: -450px;
  width: 100%;
  display: flex;
  justify-content: end;
  flex-direction: column;
  align-items: center;

  .photos-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
  }

  .text-area-animation-senior {
    margin-top: 20%;
    display: none;
    z-index: 2;
    width: 100%;
    position: absolute;
    height: 75vh;
    display: flex;
    justify-content: space-between;
    padding: 0 75px;

    > div {
      max-width: 275px;

      @media (min-width: ${props => props.theme.screen.lg}) {
        max-width: 327px;
      }
    }

    > p {
      max-width: 221px;
      color: ${({ theme }) => theme.color.white};
      ${({ theme }) => theme.font_size.medium};
      display: flex;
      align-self: center;

      @media (min-width: ${props => props.theme.screen.lg}) {
        max-width: 325px;
        ${({ theme }) => theme.font_size.large};
      }
    }

/*     .caption {
      color: ${({ theme }) => theme.color.white};
      margin-bottom: 16px;
      position: relative;
      ${({ theme }) => theme.font_size.small};
      letter-spacing: 4px;
      text-align: left;
      text-transform: uppercase;
    } */

    @media (min-width: ${props => props.theme.screen.lg}) {
      padding: 0;
    }
  }
`

const PhotoWrapper = styled.div`
  position: absolute;
  top: 200px;
`

const StyledPhoto = styled(Img)`
  width: 220px;
`

const EmptyBox = styled.div`
  width: 462px;
  height: 672px;
  display: block;
  position: absolute;

  video {
    position: relative;
    border-radius: 35%;
    height: 82%;
    top: 9%;
  }

  @media (min-width: ${props => props.theme.screen.lg}) {
    width: 670px;
    height: 973px;
  }
`

const BodyWrapper = styled.div`
  display: none;

  @media (min-width: ${props => props.theme.screen.md}) {
    display: block;
    padding-top: 180px;
  }
`

export const SectionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100vh;
  padding: 0 80px;

  @media (min-width: ${props => props.theme.screen.lg}) {
    padding: 0;
  }
`

const ImagePhoneWrapper = styled.div`
  position: absolute;
  z-index: 555;
  width: 104%;
`

const StyledPhone = styled(Img)`
  top: -20px;
  left: -9px;
`

const VideoWrapperWithCase = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export default injectIntl(Body)
